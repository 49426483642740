import * as React from 'react';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import Metadata from '../components/Metadata';

const AboutPage = () => {
  const metaDescription = 'Meet the human Ian Goodnight';

  return (
    <Layout>
      <Metadata title="About the Human" description={metaDescription} />
      <Hero />
    </Layout>
  );
};

export default AboutPage;
